import Bowser from 'bowser';

export const getBrowserAndDevice = () => {
  const bowser = Bowser.getParser(window.navigator.userAgent);

  const browser = bowser.getBrowserName().toLowerCase();
  let device = bowser.getPlatformType().toLowerCase();

  // Determina se il dispositivo è touch (per distinguere tra tablet e desktop)
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  if (device === 'desktop' && isTouchDevice) {
    device = 'tablet';
  }
  return { browser, device };
};
