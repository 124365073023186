import { FC, useState, useEffect } from "react";
import Store from "@/Store";
import { useTranslation } from "react-i18next";
import HyperLink from "@/Components/atoms/HyperLink";
import { Link } from "./Footer.type";
import { getBrowserAndDevice } from "@/utils/bowser";
import "./Footer.scss";
const Footer: FC = () => {
	const currentStep = Store.useStepsStore((store) => store.data.currentStep);
	const requestStatus = Store.useRequestStore(
		(store) => store.requestStatus.status
	);
	const footerConfigurationHeight = Store.useTemplateStore((store)=>store.data.footerConfigurationHeight);
	const { t } = useTranslation();
	const footerLinks = t("FOOTER", { returnObjects: true }) as Link[];
	const [device, setDevice]=useState<string>('');
	const [browser, setBrowser] = useState<string>('')
	const [marginBottom, setMarginBottom]= useState<number>(0);

	useEffect(()=>{
		setDevice(getBrowserAndDevice().device);
		setBrowser(getBrowserAndDevice().browser);
		if(currentStep!==2) {
			if(device==='desktop') {
				if (footerConfigurationHeight===0) {
					setMarginBottom(0);
				} else {
					setMarginBottom(footerConfigurationHeight)
				}
			} else if (device ==='tablet'){
				if (footerConfigurationHeight===0) {
					if(browser==='chrome') {
						setMarginBottom(0);
					} else {
						setMarginBottom(0);
					}
				} else {
					if(browser==='chrome') {
						setMarginBottom(footerConfigurationHeight);
					} else {
						setMarginBottom(footerConfigurationHeight);
					}
				}
			} else if (device === 'mobile') {
				setMarginBottom(footerConfigurationHeight)
			} else {
				setMarginBottom(footerConfigurationHeight)
			}
		} else {
			if(device==='desktop') {
				setMarginBottom(0);
			} else if (device ==='tablet'){
				if(browser === 'chrome') {
					setMarginBottom(78);
				} else {
					setMarginBottom(70);
				}
			} else if (device === 'mobile') {
				setMarginBottom(0);
			}
		}

		
	}, [footerConfigurationHeight, device])
	return (
		<>
			{requestStatus !== 500 &&
				requestStatus !== 400 &&
				requestStatus !== 401 &&
				requestStatus !== 404 && (
					<footer style={{marginBottom:marginBottom}}>
						<div className="footer-content">
							{footerLinks.map((link: Link, index: number) => {
								return (
									<HyperLink key={index} link={link.LINK} text={link.TEXT} />
								);
							})}
						</div>
					</footer>
				)}
		</>
	);
};

export { Footer };
