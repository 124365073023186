import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Template } from "./templateStore.type";

const InitialState = {
  footerConfigurationHeight: 0,
};

const useTemplateStore = create<Template>()(
  devtools((set) => ({
    data: InitialState,
    actions: {
      setFooterConfigurationHeight: (height:number) =>
        set(store => ({...store, data:{footerConfigurationHeight:height}})),
    },
  }))
);
export default useTemplateStore;
